* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background: #161D23;
}

a {
  display: inline-block;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  color: #FFFFFF;
}

p{
  font-family: 'Open Sans', sans-serif;
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-weight: 700;
  font-size: 48px;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 24px;
}

h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.25;
  text-transform: capitalize;
}

.container {
  padding: 0 20px;
}

.common-btn {
  border-radius: 5px;
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  display: flex;
  align-items: center;
  justify-content: center; 
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  transition: all ease-in-out 0.3s;
  padding: 11px 32px 12px; 
  cursor: pointer;
  border: 0;
  min-width: 140px;
}

.common-btn img{
  margin-left: 10px;
}


/* Header */

header{
  padding: 10px 0;
}
header .navbar-toggler{
  display: none !important;
}
header .container{
  max-width: 100%;
  width: 1400px;
}

header .navbar{
  padding: 0 !important;
  justify-content: space-between;
  align-items: center;
}
header .navbar .logo{
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}
header .navbar .logo img{
  margin-right: .75rem;
}
header .navbar-collapse{
  flex-grow: inherit;
}

header .common-btn{
  margin-right: 16px;
}

header .btn-wrap{
  display: flex;
  margin-left: auto;
}

header .navbar-collapse .navbar-nav{
  border-radius: 24px;
  background: #01152D;
  padding: 4px;
}

header .navbar-collapse .navbar-nav li a{
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 24px;
  border-radius: 28px;
}

header .navbar-collapse .navbar-nav li.active a{
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
}

header .profile{
  display: flex;
  align-items: center;
  margin-left: 64px;
}

header .profile span{
  color: #FFF;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  margin-right: 18px;
}

header .profile .img-wrap{
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  box-shadow: 0px 8px 36px 0px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  padding: 3px;
}

header .profile .img-wrap img{
 width: 42px;
 height: 42px;
}

header form{
  border-radius: 8px;
  background: #1D2833;
  backdrop-filter: blur(10px);
  padding: 12px 24px 12px 60px;
  display: flex;
  align-items: center;
   background-size: 21px;
  background-repeat: no-repeat;
  background-position: 24px 50%;
  background-image: url('../../public/images/personalcard.png');
}


form label{
  color: #FFF;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  margin: 0 !important;
  margin-right: 16px !important;
  opacity: 0.4;
}

header form button{
  background-color: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  width: 150px !important;
  outline: 0 !important;
  box-shadow: none !important;
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background-clip: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  border: 0 !important;
}

header form input.form-control::-webkit-input-placeholder { /* Edge */
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none !important;
  font-style: normal !important;
  font-weight: 300 !important;
}

header form input.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none !important;
  font-style: normal !important;
  font-weight: 300 !important;
}

header form input.form-control::placeholder {
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none !important;
  font-style: normal !important;
  font-weight: 300 !important;
}

/* header end */


.account{
  display: flex;
  min-height: calc(100vh - 40px);
}

.account .left{
  height: 100vh;
  /* width: 480px; */
  width: 33.334%;
  padding: 30px 50px;
  box-shadow: 80px 0px 150px 0px #04101F;
  position: relative;
  z-index: 1;
  overflow: auto;
}

.account .left .inner{
  max-width: 308px;
  margin: 0 auto;
}

.account .left .inner .head{
  margin-bottom: 48px;
}

.account .left .inner .common-btn{
  margin-top: 48px;
}

.account .left .inner .logo{
  margin-bottom: 85px;
}

h2.sale-start{
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 23px;
  font-weight: 600;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 16px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 230px;
  text-transform: inherit;
}

.deactivate .main-wrap{
  opacity: 0.2;
  filter: blur(2px);
  pointer-events: none;
  user-select: none;
  cursor: not-allowed;
}

form input{
  border-radius: 24px !important;
  background-color: rgba(1, 21, 45, 0.70) !important;
  backdrop-filter: blur(10px);
  color: rgba(255,255,255,0.4) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  border: 0 !important;
  padding: 13px 24px !important;
}

form input::-webkit-input-placeholder { /* Edge */
  color: rgba(255,255,255,0.4) !important;
  font-size: 16px !important;
  font-weight: 400;
}

form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255,255,255,0.4) !important;
  font-size: 16px !important;
  font-weight: 400;
}

form input::placeholder {
  color: rgba(255,255,255,0.4) !important;
  font-size: 16px !important;
  font-weight: 400;
}

form input[type="password"]{
  padding-right: 50px !important;
}

form input[type="password"] + .eye{
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}

form > div{
  position: relative;
}

form > a{
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  transition: all ease-in-out 0.2s;
  margin-top: 24px;
  font-weight: 600;
  position: relative;
}

form > a:after{
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  position: absolute;
  width: 100%;
  height: 1px;
  content: '';
  bottom: 0;
  left: 0;
}

form > a:hover{
  opacity: 0.85;
}


.account .right{
  width: 66.666%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.account.signin .right, .account.signup .right{
  background-image: url('../../public/images/login-bg.png');
}

.account.sidebar .right{
  display: block;
  width: 80%;
  flex-grow: 1;
}

.account.sidebar .left{
  width: 20%;
  padding: 30px 0 30px 30px;
}

.account.sidebar .left .inner{
  max-width: 250px;
  margin-left: auto;
  margin-right: 0;
}

.account.sidebar .left .navbar-nav{
  max-width: 280px;
  margin-left: auto;
}

.account.sidebar .left .navbar-nav li{
  margin-bottom: 16px;
}

.account.sidebar .left .navbar-nav li:last-child{
  margin-bottom: 0;
}

.account.sidebar .left .navbar-nav li a{
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 28px 0 0 28px;
  display: flex;
  align-items: center;
}

.account.sidebar .left .navbar-nav li a img{
  margin-right: 12px;
}

.account.sidebar .left .navbar-nav li a.active,
.account.sidebar .left .navbar-nav li a:hover{
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
}
.account.kyc .right{
  align-items: flex-end;
  justify-content: flex-start;
  background-image: url('../../public/images/kyc-bg.png');
}

.account .right > span{
  opacity: 0.1;
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 160px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
}

.form-wrap{
  border-radius: 5px;
  background: #1D2833;
  text-align: center;
  padding: 48px;
  max-width: 404px;
  margin: 114px auto 30px;
  position: relative;
}

.form-wrap form input{
  background-color: #061C35 !important;
}

.form-wrap form button{
  margin: 48px auto 0;
}
.form-wrap .head{
  max-width: 340px;
  margin: 0 auto 32px;
}

.form-wrap .head h1{
  font-size: 32px;
}

.form-wrap .nav-tabs{
  border: 0 !important;
  border-radius: 5px;
  padding: 0;
  margin-bottom: 22px;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 4px;
  background: rgba(255,255,255,0.05);
}
.form-wrap .nav-tabs li{
  width: 50%;
}
.form-wrap .nav-tabs li button{
  border: 0 !important;
  border-radius: 5px;
  color: #FFF;
  font-size: 20px;
  font-weight: 600;
  padding: 0;
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background-clip: none;
  font-style: normal;
  border: 0 ;
  cursor: pointer;
  line-height: 1.2;
  padding: 5px 35px;
}

.form-wrap .nav-tabs li button.active{
  border: 0 !important; 
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  background-clip: unset !important;
  -webkit-background-clip: unset !important;
  -webkit-text-fill-color: unset;
  background-clip: unset;
  color: #fff;
  font-weight: 400;
}

.form-wrap.minting{
  max-width: 546px;
}

.form-wrap.minting form .form-group{
  border-radius: 5px;
  background: #3C3F47;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
}

.form-wrap.minting form label{
  font-family: 'Inter', sans-serif;
}

.form-wrap.minting form input{
  background-color: transparent !important;
  border-radius: 0 !important;
  text-align: right;
  padding: 0 !important;
  max-width: 130px;
}


/* DAO */
.dao-proposal{
  min-height: calc(100vh - 116px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dao-proposal .container .inner{
  border-radius: 32px;
  background: #01152D;
  padding: 24px;
}

.dao-proposal .table-wrapper {
  overflow: auto;
}

.dao-proposal .table-wrapper table {
  min-width: 700px;
}

.dao-proposal .table-wrapper table {
  min-width: 920px;
}

.dao-proposal .filters{
  margin-bottom: 32px;
}

.dao-proposal .filters form{
  display: flex;
  justify-content: space-between;
}

.dao-proposal .filters form .form-group{
  width: calc(100% - 257px);
}

.dao-proposal .filters form button{
  width: 233px;
}

.dao-proposal .filters form input{
  background-image: url(../../public/images/search-normal.png);
  background-position: calc(100% - 24px) 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  background-color: #061C35 !important;
  padding-right: 60px !important;
}

.dao-proposal .table-wrapper h2 {
  text-align: left;
}

.dao-proposal .table-wrapper table {
  width: 100%;
}

.dao-proposal .table-wrapper table thead {
  border-color: #ffffff1a;
  text-align: left;
}

.dao-proposal .table-wrapper table thead th {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dao-proposal .table-wrapper table tbody td {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  border: none;
  padding: 16px;
}

.dao-proposal .table-wrapper table tbody td.status {
  color: rgba(255, 255, 255, 0.4);
}

.dao-proposal .table-wrapper table tbody td.status.green {
  color: #05FF00;
}

.dao-proposal .table-wrapper table tbody tr.active td.status {
  color: #05FF00;
}

.dao-proposal .table-wrapper table tbody tr td.vote-reject img {
  width: 20px;
  height: 20px;
}

.dao-proposal .table-wrapper table tbody tr.active td.vote-reject img {
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.dao-proposal .table-wrapper table tbody tr td.vote-reject img.expired {
  cursor: not-allowed;
}

.dao-proposal .table-wrapper table tbody tr.active td.vote-reject img.expired {
  display: none;
}

.dao-proposal .table-wrapper table tbody tr:not(.active) td.vote-reject img.expired {
  display: inline-block;
}

.dao-proposal .table-wrapper table tbody tr:not(.active) td.vote-reject img:not(.expired),
.dao-proposal .table-wrapper table tbody tr.active td.vote-reject img.rejected,
.dao-proposal .table-wrapper table tbody tr.active td.vote-reject img.voted {
  display: none;
}

.dao-proposal .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.do-vote {
  display: none;
}

.dao-proposal .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.voted {
  display: inline-block;
}

.dao-proposal .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.do-reject {
  display: none;
}

.dao-proposal .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.rejected {
  display: inline-block;
}

.dao-proposal .table-wrapper table tbody tr.active td.vote-reject img:hover {
  opacity: 0.8;
}

.dao-proposal .table-wrapper table tbody td.options {
  display: flex;
  align-items: center;
  gap: 35px;
}

.dao-proposal .table-wrapper table tbody td.options a{
  border-radius: 2px;
    background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 8px;
}

.dao-proposal .table-wrapper table tbody td.options img {
  cursor: pointer;
}

.dao-proposal .detail-row form input {
  margin: 0;
}

.dao-proposal .table-wrapper table tbody td.vote-count p {
  display: inline-block;
  color: rgba(255, 255, 255, 0.40);
  font-size: 12px;
  margin-right: 20px;
}

.dao-proposal .table-wrapper table tbody td.vote-count p:first-child{
  width: 110px;
}

.dao-proposal .table-wrapper table tbody td.vote-count p:last-child {
  display: inline-block;
  font-size: 12px;
  margin-right: 0;
}

.dao-proposal .table-wrapper table tbody td.vote-count p img {
  display: inline-block;
  margin-right: 8px;
  vertical-align: text-top;
}

.dao-proposal .table-wrapper table tbody td.vote-reject p {
  display: inline-block;
  margin-right: 24px;
  vertical-align: middle;
}

.dao-proposal .table-wrapper table tbody td.vote-reject p:last-child {
  margin-right: 0;
}

.dao-proposal .table-wrapper table button{
  padding: 8px 30px;
  min-width: initial;
  line-height: 1.4;
}

/* Stake */

.collection-wrap{
  margin-top: 40px;
  margin-bottom: -50px;
}

.colletion-box{
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  margin-bottom: 50px;
}

.colletion-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(1, 21, 45, 0.00) 0%, #01152D 100%);
}

.colletion-box > img, .colletion-box > a > img{
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.colletion-box .content{
  position: absolute;
  bottom: 0;
  padding: 20px;
  width: 100%;
}

.colletion-box .content .content-head{
  display: flex;
  align-items: flex-start;
}

.colletion-box  .content-head img{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.colletion-box .content-head > div{
  padding-left: 12px;
  width: calc(100% - 48px);
}

.colletion-box .content-head h3{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.colletion-box .content-head span{
  font-size: 16px;
}

.colletion-box .btns-wrapper{
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.colletion-box .btns-wrapper .stake-unstake{
  display: flex;
  background: #fff;
  border-radius: 50px;
  padding: 4px;
}

.colletion-box .btns-wrapper .stake-unstake button{
  background-color: transparent !important;
  border: 0 !important;
}

.colletion-box .btns-wrapper button.active,
.colletion-box .btns-wrapper button:hover{
  color: #fff !important;
}

.colletion-box .btns-wrapper button{
  color: #061C35;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 22px;
  border-radius: 32px;
}

.colletion-box .btns-wrapper > button{
  border: 1px solid var(--grdnt, #FFA500) !important;
  background: #061C35 !important;
  color: #fff !important;
}

.colletion-box .btns-wrapper button.active,
.colletion-box .btns-wrapper button:hover,
.colletion-box .btns-wrapper > button.active,
.colletion-box .btns-wrapper > button:hover{
  color: #fff !important;
  background: linear-gradient(135deg, #FFA500 0%, #FF5C3D 100%) !important;
}

.react-responsive-modal-modal.disclaimer-modal{
  border-radius: 0 !important;
  background-color: #272A33 !important;
  padding: 25px 20px 30px !important;
  width: 612px !important;
  max-width: calc(100% - 2.4rem);
}

.react-responsive-modal-modal.disclaimer-modal button:last-child{
  display: none;
}

.react-responsive-modal-modal.disclaimer-modal button.close,
.react-responsive-modal-modal.steps-modal button.close{
  background: transparent;
  border: 0;
  right: 1rem;
  top: 1rem;
  position: absolute;
  outline: 0;
  z-index: 2;
}

.react-responsive-modal-modal.disclaimer-modal h2{
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.react-responsive-modal-modal.disclaimer-modal p{
    font-size: 15px;
    line-height: 1.5;
    text-align: left;
    color: #A9AAAD;
    margin-bottom: 12px;
}

.react-responsive-modal-modal.disclaimer-modal.minted-modal{
  width: 362px !important;
  border-radius: 20px !important;
  border: 1px solid #30bd97;
  background-color: #070707 !important;
}

.react-responsive-modal-modal.disclaimer-modal.minted-modal h2{
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: none !important;
}

.react-responsive-modal-modal.minted-modal .img-wrap{
    width: 300px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 20px;
}

.react-responsive-modal-modal.minted-modal .img-wrap img{
  margin: -8px;
  max-width: calc(100% + 16px);
}

footer{
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  padding: 10px 15px;
  text-align: center;
  width: 100%;
}

footer .copyright{
  color: #fff;
  font-size: 13px;
  font-weight: 400;
}

.react-responsive-modal-modal.microeconomy-modal {
  border-radius: 0 !important;
  /* border: 1px solid #55cbc6 !important; */
  background-color: #272A33 !important;
  padding: 50px 20px !important;
  width: 612px !important;
  max-width: calc(100% - 2.4rem);
}

.microeconomy-modal button:last-child {
  display: none;
}

.react-responsive-modal-modal.microeconomy-modal.steps-modal {
  margin: 0;
  max-width: 100%;
  padding: 20px !important;
  border: 0 !important;
  border-radius: 5px !important;
  background: #1D2833 !important;
  width: 268px !important;
}
.react-responsive-modal-modal.microeconomy-modal.steps-modal .modal-body h3 {
  font-size: 20px;
  text-align: left;
}

.react-responsive-modal-modal.microeconomy-modal.steps-modal .modal-body p {
  margin-bottom: 20px;
  font-size: 14px;
}
.react-responsive-modal-modal.microeconomy-modal.steps-modal .modal-body > img{
  width: 100%;
  border: 1px solid #3bcde3;
  border-radius: 4px;
}
.react-responsive-modal-modal.microeconomy-modal .modal-body h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.3;
  color: #FFFFFF;
  margin-bottom: 10px;
}
.react-responsive-modal-modal.microeconomy-modal.steps-modal .modal-body p span {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 1200px) {
  .container {
      max-width: 1360px;
  }
}

@media (max-width: 1600px) {
  .form-wrap {
      margin: 80px auto 30px;
  }

  .colletion-box > img, .colletion-box > a > img{
      width: 100%;
      height: 340px;
      object-fit: cover;
    }
}

@media (max-width: 991px) {
  header .navbar{
    justify-content: center;
  }

  header .common-btn{
    width: 200px;
  }
}

@media (max-width: 780px) {
  header .navbar{
    flex-direction: column-reverse;
  }

  header .common-btn{
    margin: 16px 8px 0;
  }
}

@media (max-width: 575px) {
  .form-wrap{
    padding: 48px 20px;
    margin: 60px auto 30px;
  }

  header .common-btn {
    width: 170px;
    padding: 11px 10px 12px;
  }
}

@media (max-width: 450px) {
  header .common-btn {
    width: 140px;
  }

}

@media (max-width: 400px) {
  .react-responsive-modal-modal.minted-modal .img-wrap {
    width: 270px;
  }
}